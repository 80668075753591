import './App.css';
import background from './image1.jpeg';


const contactUs = () => (
    <form className='contactButton'>
      <input type='submit' value='contact us'></input>
    </form>
);


const aboutUs = () => (
    <form className='aboutButton'>
      <input type='submit' value='who we are'></input>
    </form>
);


// Main Button ~middle of site
const scheduleDetail = () => (
    <form className='scheduleButton'>
      <input type='submit' value='Schedule a cleaning'></input>
    </form>

);


function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${background})`}}>
      <div className="header">
      <header>
      Priority Clean BG
        <div className="buttons">
          {scheduleDetail()}
          {aboutUs()}
          {contactUs()}
        </div>
      </header>
      </div>
    </div>
  );
}

export default App;
